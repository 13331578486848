<template>
  <div id="onlineFix">
    <div class="logo-img">
      <img :src="themeData.darkLogoList">
    </div>
    <a-result
      status="403"
      style="margin-top: 64px"
      :title="$t('common_text_049')"
      :sub-title="`${$t('common_text_050')}${ onlineTime }${$t('common_text_051')}`"
    />
  </div>
</template>
<script>
import { reactive, toRefs, inject, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { _home } from '@/api'
export default {
  name: 'OnlineFix',
  setup(props) {
    let timer = ''
    const route = useRoute()
    const state = reactive({
      themeData: inject('$themeData'),
      onlineTime: ''
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const init = () => {
      if (window.history && window.history.pushState) {
        window.onpopstate = function () {
          window.history.pushState('forward', null, '')
          window.history.forward(1)
        }
      }
      window.history.pushState('forward', null, '')// 在IE中必须得有这两行
      window.history.forward(1)

      state.onlineTime = route.query.onlineTime || ''

      timer = setInterval(() => {
        loadListener()
      }, 1000 * 60)
    }

    const loadListener = () => {
      state.onlineTime = route.query.onlineTime || ''
      return _home.userHealth()
    }

    init()

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#onlineFix {
  padding: 36px 64px;
  .logo-img {
    text-align: left;
    img {
      width: 180px;
    }
  }
}
</style>
